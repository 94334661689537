import React from 'react';
import styled from 'styled-components';

const ErrorComponent = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .emoji {
    font-size: 5rem;
    margin-bottom: 20px;
  }
  .message {
    font-size: 1.5rem;
    color: #333;
  }
  .subMessage {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
  },
`;

export default function Error() {
  return (
    <ErrorComponent>
      <div className='emoji'>
        <span role='img' aria-label='emoji of a work in progress signage'>
          🚧
        </span>
      </div>
      <div className='message'>Oops! Something went wrong...</div>
      <div className='subMessage'>
        We're working hard to fix it! Hang tight{' '}
        <span role='img' aria-label='smiley with sunglasses'>
          😎
        </span>
      </div>
    </ErrorComponent>
  );
}
